import React, { PureComponent } from "react";
import CreateCustomerOrderAnonymous from "./CreateCustomerOrderAnonymous";
import Tooltip from "../../common/Tooltip";
import { PropertyType } from "../../../utils/propertyUtils";
import { Property } from "../../../model/property.types";
import { getArticleProperty } from "../../../utils/commodityUtils";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { formatCurrency, formatUnit } from "../../../utils/baseUtils";
import { CO_TRANSPORT, T_AIRFREIGHT, T_SEAFREIGHT, T_WAREHOUSE } from "../../../model/customerOrder.types";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import AnonymousBlurText from "../../common/anonymous/AnonymousBlurText";
import { CustomerArticleExtended, formatArticleUnit } from "../../../utils/productArticleUtils";
import { getDeliveryTimeLabel } from "../../../utils/customerOrderUtils";

interface CommodityPageSummaryAnonymousProps {
  article: CustomerArticleExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CommodityPageSummaryAnonymousState {
  step: number;
  loading: boolean;
}

class CommodityPageSummaryAnonymous extends PureComponent<
  CommodityPageSummaryAnonymousProps,
  CommodityPageSummaryAnonymousState
> {
  constructor(props: CommodityPageSummaryAnonymousProps) {
    super(props);
    const steps = this.getOrderVolumeSteps();
    this.state = {
      step: steps[5],
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  handleClickStep = (step: number) => this.setState({ step });

  handleClickBuyNow = async () => {
    const createCustomerOrder = this.props.context.refMap["CreateCustomerOrderAnonymous"] as
      | CreateCustomerOrderAnonymous
      | undefined;
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    if (createCustomerOrder) {
      await createCustomerOrder.handleBlurAmount(this.state.step);
    }
  };

  /**
   * Retrieve an array that contains common order volume steps. Vitamins got smaller steps since they are expensive.
   * @returns { Array<number> } Array with order volume steps
   */
  getOrderVolumeSteps = (): Array<number> => {
    const { article } = this.props;
    const category = getArticleProperty(article.properties, PropertyType.CATEGORY) as Property | null;
    if (category?.name.en === "Vitamin") {
      return [1, 5, 10, 50, 250, 1000];
    } else {
      return [25, 100, 500, 1000, 2500, 10000];
    }
  };

  getMatrixButtonBorderRadius = (idx: number) => {
    const borderRadius = ["0", "0", "0", "0"];
    const roundedRadius = "5px";
    if (idx === 0) {
      borderRadius[0] = roundedRadius;
    } else if (idx === 2) {
      borderRadius[1] = roundedRadius;
    } else if (idx === 3) {
      borderRadius[3] = roundedRadius;
    } else if (idx === 5) {
      borderRadius[2] = roundedRadius;
    }
    return borderRadius.join(" ");
  };

  render() {
    const { article } = this.props;
    const { step, loading } = this.state;

    return (
      <div className="card mb-5 mb-xl-8 bg-white">
        <div className="card-body">
          <div className="text-center">
            <div className="fw-bolder text-white text-center" style={{ fontSize: "2.2rem" }}>
              <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
            </div>
            <div className="fw-bolder mb-3 text-white text-center">
              <div className="text-center text-muted">
                {step.toLocaleString()} {formatArticleUnit(article.unit, article)}
              </div>
            </div>
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bolder rotate">Price Matrix</div>
            <Tooltip
              tooltipText="Lowest prices available. Prices may vary depending on your individual purchase quantity."
              delay={{ show: 125, hide: 0 }}
            >
              <span className="text-muted opacity-04 fw-bold d-block fs-7 float-right">
                <i className="fa fa-info-circle"></i>
              </span>
            </Tooltip>
          </div>
          <div className="bg-light2">
            <div className="d-grid h-100" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
              {this.getOrderVolumeSteps().map((s: number, idx) => (
                <button
                  key={s}
                  className={"btn btn-light btn-sm " + (step === s && "active")}
                  style={{ borderRadius: this.getMatrixButtonBorderRadius(idx) }}
                  onClick={() => this.handleClickStep(s)}
                >
                  {s.toLocaleString()} {formatArticleUnit(article.unit, article)}
                </button>
              ))}
            </div>
          </div>
          <div className="d-flex flex-stack fs-4 py-3 pt-10">
            <div className="fw-bolder rotate">Price Details</div>
            <Tooltip
              tooltipText="Lowest prices available. Prices may vary depending on your individual purchase quantity."
              delay={{ show: 125, hide: 0 }}
            >
              <span className="text-muted opacity-04 fw-bold d-block fs-7 float-right">
                <i className="fa fa-info-circle"></i>
              </span>
            </Tooltip>
          </div>
          <div className="bg-light2 px-5">
            <div className="border-bottom-dark-gray" />
            <SummaryPriceEntry method={T_SEAFREIGHT} />
            <SummaryPriceEntry method={T_AIRFREIGHT} />
            <SummaryPriceEntry method={T_WAREHOUSE} />
            <div className="d-flex flex-stack my-4">
              <div className="flex-grow-1 me-2">
                <span className="text-gray-800 fs-6 fw-bolder">Warehouse Amount</span>
              </div>
              <span className={"ml-2 text-muted"}>
                {!loading ? (
                  <AnonymousBlurText>{formatUnit(1000, article.unit ? article.unit : "kg")}</AnonymousBlurText>
                ) : (
                  <AnonymousBlurText>Not available</AnonymousBlurText>
                )}
              </span>
            </div>
            <div className="d-flex flex-stack my-4">
              <div className="flex-grow-1 me-2">
                <span className="text-gray-800 fs-6 fw-bolder">Incoming Amount</span>
                <span className="text-muted fw-bold d-block fs-7">
                  Delivery in <AnonymousBlurText>7 weeks</AnonymousBlurText>
                </span>
              </div>
              <span className={"ml-2 text-muted"}>
                {!loading ? (
                  <AnonymousBlurText>{formatUnit(1000, article.unit ? article.unit : "kg")}</AnonymousBlurText>
                ) : (
                  <AnonymousBlurText>Not available</AnonymousBlurText>
                )}
              </span>
            </div>
          </div>
          <button
            className="btn w-100 mt-10 btn-success"
            onClick={loading ? undefined : this.handleClickBuyNow}
            disabled={loading}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }
}

interface SummaryPriceEntryProps {
  method: CO_TRANSPORT;
}

class SummaryPriceEntry extends PureComponent<SummaryPriceEntryProps> {
  getTitle = () => {
    const { method } = this.props;
    switch (method) {
      case T_SEAFREIGHT:
        return "Seafreight";
      case T_AIRFREIGHT:
        return "Airfreight";
      case T_WAREHOUSE:
        return "Warehouse";
      default:
        return "";
    }
  };

  render() {
    return (
      <div className="d-flex flex-stack my-4">
        <div className="flex-grow-1 me-2">
          <span className="text-gray-800 fs-6 fw-bolder">{this.getTitle()}</span>
          <span className="text-muted fw-bold d-block fs-7">
            Delivery in
            <AnonymousBlurText>7 weeks</AnonymousBlurText>
          </span>
        </div>
        <AnonymousBlurText additionalClasses="ml-2 text-success">
          {formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}
        </AnonymousBlurText>
      </div>
    );
  }
}

export default CommodityPageSummaryAnonymous;
