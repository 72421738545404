import _ from "lodash";
import React, { PureComponent } from "react";
import CommodityPageOrderUpdates from "../common/CommodityPageOrderUpdates";
import OrderWidget from "../../common/OrderWidget";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { CustomerCustomerOrderExtended } from "../../../model/customer/customerCustomerOrder.types";
import { CustomerArticleExtended } from "../../../utils/productArticleUtils";
import { extendCustomerCustomerOrder } from "../../../utils/dataTransformationUtils";

interface CommodityPageOrdersProps {
  article: CustomerArticleExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CommodityPageOrdersState {
  activeOrders: Array<CustomerCustomerOrderExtended>;
  search: string;
}

class CommodityPageOrdersWidget extends PureComponent<CommodityPageOrdersProps, CommodityPageOrdersState> {
  constructor(props: CommodityPageOrdersProps) {
    super(props);
    this.state = {
      search: "",
      activeOrders: this.getRelevantOrders(props),
    };
  }

  componentDidUpdate = (
    prevProps: Readonly<CommodityPageOrdersProps>,
    prevState: Readonly<CommodityPageOrdersState>
  ) => {
    if (!_.isEqual(prevProps.article, this.props.article)) {
      this.setState({ activeOrders: this.getRelevantOrders(this.props) });
      return;
    }
    // Update if a change appeared, but it was not a state update, e.g. search. We assume it must be context update then
    if (_.isEqual(prevState, this.state)) {
      const orders = this.getRelevantOrders(this.props);
      if (!_.isEqual(orders, this.state.activeOrders)) this.setState({ activeOrders: orders });
    }
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  /**
   * Get a list of customer orders for the commodity passed in the components properties
   * @param props the components properties
   * @returns {Array<CustomerCustomerOrderExtended>} list of customer orders for the passed commodity
   */
  getRelevantOrders = (props: CommodityPageOrdersProps): Array<CustomerCustomerOrderExtended> => {
    const { customerOrder } = props.context;
    const cOs = customerOrder.filter((cO) => cO.commodity._id.toString() === props.article._id.toString());
    return cOs.map((cO) => extendCustomerCustomerOrder(cO, props.context));
  };

  render() {
    const { context } = this.props;
    const { activeOrders } = this.state;
    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">Current Orders</span>
            </h3>
          </div>
          <div className="card-body py-0 modal-content-scroll mb-4" style={{ maxHeight: 500 }}>
            {activeOrders.length > 0 ? (
              activeOrders.map((o) => <OrderWidget key={o._id.toString()} type="commodity" order={o} preview={true} />)
            ) : (
              <div className="card bg-light2">
                <span className="text-muted d-block mx-auto my-5">No orders yet</span>
              </div>
            )}
          </div>
        </div>
        {activeOrders.length > 0 ? (
          <CommodityPageOrderUpdates orders={activeOrders} context={context} lessWidth={true} />
        ) : (
          <div className="card bg-white mt-8">
            <div className="card-header border-0 mt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">Order Updates</span>
              </h3>
            </div>
            <div className="card-body pt-0 modal-content-scroll" style={{ maxHeight: 500 }}>
              <div className="card bg-light2">
                <span className="text-muted d-block mx-auto my-5">No updates yet</span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default CommodityPageOrdersWidget;
